import { Skeleton, Stack, Typography } from '@mui/joy';
import { PageHeaderLoadingSkeleton } from 'elements/PageHeaderLoadingSkeleton';

export const ThankYouLoadingSkeleton = () => {
  return (
    <>
      <PageHeaderLoadingSkeleton />
      <section>
        <Stack
          alignItems={'center'}
          spacing={{ xs: 5, md: 8 }}
        >
          <section style={{ maxWidth: '720px' }}>
            <Typography level={'h1'} sx={{ textAlign: 'center', mb: 3 }}>
              <Skeleton>
              Thank you
              </Skeleton>
            </Typography>

            <Typography level={'body-md'} sx={{ textAlign: 'center' }}>
              <Skeleton>
              lorem ipusm dolor sit amet, consectetur adipiscing elit. Nulla
              consectetur sed quam et aliquam. Nam eget nisl sit amet mauris
              ullamcorper dapibus. Suspendisse potenti. Sed sed lorem eget
              sapien blandit gravida. Sed euismod, nisl et aliquam.
              </Skeleton>
            </Typography>
          </section>
        </Stack>
      </section>
    </>
  );
};
